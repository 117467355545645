import * as RadixNav from '@radix-ui/react-navigation-menu'
import { Flex, Separator } from '@radix-ui/themes'
import { STAND } from 'app/constants'
import { Stand } from 'app/types'
import { DataTestIDs, RouterPathes } from 'shared/constants'
import { XyroLogoLink } from 'shared/ui'
import { NavigationHelpLinks } from './navigation-help-links'
import { NavigationLink } from './navigation-link'
import styles from '../navigation.module.scss'

export const Navigation: React.FC = () => {
  return (
    <RadixNav.Root
      className={styles.navigationMenuRoot}
      orientation='vertical'
    >
      <Flex
        direction='column'
        gap='8'
        height='100%'
      >
        <XyroLogoLink withText={false} />

        <RadixNav.List className={styles.navigationMenuList}>
          <NavigationLink
            iconType='home'
            to={RouterPathes.home}
          />

          <Separator size={'1'} />

          <NavigationLink
            iconType='up-down'
            to={RouterPathes.upDown}
            title='Up / down'
            dataTestID={upDownSidePanel}
          />

          <NavigationLink
            iconType='bulls-eye'
            to={RouterPathes.bullsEye}
            title="Bull's Eye"
            dataTestID={bullsEyeSidePanel}
          />

          <NavigationLink
            iconType='one-vs-one'
            to={RouterPathes.oneVsOne}
            title='1 vs 1'
            dataTestID={oneVsOneSidePanel}
          />

          <NavigationLink
            iconType='setups'
            to={RouterPathes.setups}
            title='Setups'
            dataTestID={setupsSidePanel}
          />

          <Separator
            size={'1'}
            mt={'3'}
          />

          <NavigationLink
            iconType='rewards'
            to={RouterPathes.rewards}
            title='Rewards'
            dataTestID={rewardsSidePanel}
          />

          <NavigationLink
            iconType='referrals'
            to={RouterPathes.referrals}
            title='Referrals'
            dataTestID={referralsSidePanel}
          />

          {STAND !== Stand.mainnet && (
            <NavigationLink
              to={RouterPathes.buyback}
              iconType='buyback'
              title='Buyback'
              dataTestID={buybackSidePanel}
            />
          )}
        </RadixNav.List>
      </Flex>
      <NavigationHelpLinks />
    </RadixNav.Root>
  )
}

const {
  setupsSidePanel,
  upDownSidePanel,
  rewardsSidePanel,
  referralsSidePanel,
  bullsEyeSidePanel,
  oneVsOneSidePanel,
  buybackSidePanel
} = DataTestIDs
