import { FC, SVGProps } from 'react'
import {
  BullsEyeNavIcon,
  BuyBackIcon,
  CupIcon,
  HomeNavIcon,
  OneVsOneNavIcon,
  ReferralsTriangle,
  SetupsNavIcon,
  UpDownNavIcon
} from 'shared/icons'
import { ModeType } from './types'

export const ICON_SIZE = '4rem'
export const MENU_ICON_SIZE = '3rem'

export const MODE_IDS = [
  'up-down',
  'bulls-eye',
  'one-vs-one',
  'setups',
  'rewards'
] as const

export const MODE_ID_TO_MODE_MAP: Record<
  ModeType,
  { icon: FC<SVGProps<SVGSVGElement>>; name: string }
> = {
  'up-down': { icon: UpDownNavIcon, name: 'Up/Down' },
  'bulls-eye': { icon: BullsEyeNavIcon, name: 'Bull’s Eye' },
  'one-vs-one': { icon: OneVsOneNavIcon, name: '1vs1' },
  setups: { icon: SetupsNavIcon, name: 'Setups' },
  rewards: { icon: CupIcon, name: 'Rewards' }
}

export const MODE_TYPE_TO_ICON_MAP = {
  home: HomeNavIcon,
  setups: SetupsNavIcon,
  'bulls-eye': BullsEyeNavIcon,
  'one-vs-one': OneVsOneNavIcon,
  'up-down': UpDownNavIcon,
  rewards: CupIcon,
  referrals: ReferralsTriangle,
  buyback: BuyBackIcon
}

export const MODE_ROUTES = [
  'up-down',
  'bulls-eye',
  'one-vs-one',
  'setups',
  'rewards'
] as const
